import styles from "./FeedbackScreen.module.css";
import {useNavigate} from "react-router-dom";
import feedbackImage from "../../images/feedback image.png"

const FeedbackScreen = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.feedbackContainer}>
      <div className={styles.feedbackWrapperContainer}>
        <img
          src={feedbackImage}
          alt="FeedbackImage"
          className={styles.feedbackImage}
        />
        <div className={styles.feedbackHeader}>
          Thank You!
        </div>
        <p>Thanks, For Your Feedback</p>
        <p>For Raising Another Ticket Click Below</p>
        <button
          className={styles.newTicketText}
          onClick={() => navigate("/")}
        >
          Click Here
        </button>
      </div>
    </div>
  );
};

export default FeedbackScreen;
