import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { notyf } from "./notyf";

export const baseUrl = "/admin-api/v1";

const config: AxiosRequestConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    withCredentials: true,
  },
  baseURL: baseUrl,
};

const requester = axios.create(config);
requester.interceptors.response.use(
  (response) => response.data,
  function (error: AxiosError) {
    if (error.response) {
      if (error.message === "Network Error") {
        notyf.error(`Connection Failed`);
      } else if (
        error.response.status > 400 &&
        error.response.status < 500 &&
        error.response.status !== 404 &&
        error.response.status !== 401 &&
        error.response.status !== 403 &&
        error.response.status !== 409
      ) {
        notyf.error(`Ooops!! Something Went Wrong`);
      } else if (error.response.status >= 500) {
        notyf.error(`Sorry!! The service is temporarily unavailable`);
      }
      return Promise.reject(error);
    }
  }
);

export default requester;
