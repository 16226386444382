import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import SkyFormScreen from "./containers/SkyFormScreen/SkyFormScreen";
import BaseScreen from "./containers/BaseScreen/BaseScreen";
import FeedbackScreen from "./containers/FeedbackScreen/FeedbackScreen";

const App = () => {

  return (
    <>
      <Routes>
        <Route path="/*" element={<BaseScreen/>}>
          <Route path="" element={<SkyFormScreen/>}/>
          <Route path="feedback" element={<FeedbackScreen/>}/>
        </Route>
      </Routes>
    </>
  );

}

export default App;
