import HeaderComponent from "../../components/business/HeaderComponent/HeaderComponent";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BaseScreen = () => {

  return(
    <div>
      <HeaderComponent/>
      <Outlet/>
      <ToastContainer/>
    </div>
  )
}

export default BaseScreen;