import styles from "./SkyFormScreen.module.css";
import {useState} from "react";
import classNames from "classnames";
import SkyButton, {ButtonSize, ButtonType} from "../../components/base/SkyButton/SkyButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {createTicket, generateOTP} from "../../api/endpoint";
import {notyf} from "../../notyf";
import DotLoader from "../../components/business/DotLoader/DotLoader";
import SkyRichTextEditor from "../../components/base/SkyRichTextEditor/SkyRichTextEditor";

const SkyFormScreen = () => {
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpFieldVisible, setIsOtpFieldVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,30}@[a-zA-Z0-9-]{1,30}(?:\.[a-zA-Z0-9-]{1,30})+$/;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commentBoxHeight, setCommentBoxHeight] = useState<string>("6rem");

  const handleGetOtp = () => {
    setErrorMessage("");
    if (subject.trim() === "" || description.trim() === "") {
      setErrorMessage("Field cannot be empty");
      return;
    }

    if (!validEmailRegex.test(email)) {
      setErrorMessage("Invalid Email");
      return;
    }
    setIsLoading(true);

    generateOTP(email)
      .then((_) => {
        notyf.success("OTP has been sent to your mail");
        setIsOtpFieldVisible(true);
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.error
            ? error.response.data.error
            : notyf.error("Error while generating OTP")
        );
      })
      .finally(() => {
        setIsLoading(false)
      });
  }
  const handleSubmit = () => {
    setErrorMessage("");
    if (otp.trim() === "") {
      setErrorMessage("Enter the OTP");
      return;
    }
    setIsLoading(true);

    const ticketRequest = {
      email: email,
      subject: subject,
      description: description,
      otp: otp,
    }
    createTicket(ticketRequest)
      .then((_) => {
        notyf.success("Complaint has been submitted successfully");
        setIsOtpFieldVisible(false);
        setCommentBoxHeight("6rem");
        handleReset();
        setOtp("");
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.error
            ? error.response.data.error
            : notyf.error("Error while submitting Complain")
        );
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  const handleReset = () => {
    setEmail("");
    setDescription("");
    setSubject("");
    setErrorMessage("");
    setCommentBoxHeight("6rem");
  }

  return (
    <div className={styles.skyFormContainer} data-testid="skyform">
      <div className={styles.skyFormWrapperContainer}>
        <div className={styles.skyFormHeader}>
          Submit Your Complaint
        </div>
        <div className={classNames(styles.formInputContainer, styles.flex)}>
          <div className={styles.formInputTitle}>Email:</div>
          <div className={styles.formInputWrapper}>
            <input
              className={styles.inputfield}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isOtpFieldVisible}
              data-testid="email"
            />
          </div>
        </div>
        <div className={styles.formInputContainer}>
          <div className={styles.formInputTitle}>Subject :</div>
          <div className={styles.formInputWrapper}>
            <input
              className={styles.inputfield}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              disabled={isOtpFieldVisible}
              data-testid="subject"
            />
          </div>
        </div>
        <div className={styles.formInputContainer}>
          <div className={styles.formInputTitle}>Description :</div>
          <SkyRichTextEditor
            height={commentBoxHeight}
            onChange={(description) => setDescription(description)}
            onFocus={() => setCommentBoxHeight("15rem")}
            isCleared={description === ""}
            isDisabled={isOtpFieldVisible}
          />
        </div>
        {isOtpFieldVisible && (
          <div className={styles.formInputContainer}>
            <div className={styles.formInputTitle}>OTP (Sent in your email id) :</div>
            <div className={styles.formInputWrapper}>
              <input
                className={styles.inputfield}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                data-testid="otp"
              />
            </div>
          </div>
        )}

        <div className={styles.errorMessage}>{errorMessage}</div>
        {isLoading ?
          <DotLoader/>
          : (
            <>
              {!isOtpFieldVisible ? (
                <div className={styles.buttonContainer}>
                  <SkyButton
                    text={"Get OTP"}
                    size={ButtonSize.SMALL}
                    onClick={handleGetOtp}
                    testId={"getOtp"}
                  />
                  <SkyButton
                    text={"Reset"}
                    type={ButtonType.CANCEL}
                    size={ButtonSize.SMALL}
                    onClick={handleReset}
                    testId={"reset"}
                  />
                </div>
              ) : (
                <div className={classNames(styles.buttonContainer, styles.flexDirection)}>
                  <SkyButton
                    text={"Submit"}
                    size={ButtonSize.SMALL}
                    onClick={handleSubmit}
                    testId={"submit"}
                  />
                  <div
                    className={styles.backToEdit}
                    onClick={() => setIsOtpFieldVisible(false)}
                    data-testid={"back-to-edit"}
                  >
                    <ArrowBackIcon/>
                    Back to Edit
                  </div>
                </div>
              )}
            </>
          )}
      </div>
    </div>
  )
}

export default SkyFormScreen;
