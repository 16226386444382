import requester from "../requester";
import {CreateTicketRequest} from "../models/request/CreateTicketRequest";

export const createTicketEndPoint = "/customer/createTicket";
export const generateOTPEndPoint = (email:string) =>  `/customer/otp?email=${email}`;

export const getHeaders = ( accept?: string) => {
  const headers: {
    "Content-Type": string;
    withCredentials: boolean;
    Authorization?: string;
    Accept?: string;
  } = {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: accept ? accept : "",
    withCredentials: true,
  }
  return headers;
};


export const generateOTP = (
  data: string
): Promise<any> => {
  const headers = getHeaders();
  return requester.post(generateOTPEndPoint(data), data, {
    headers,
  });
};

export const createTicket = (
  createTicket: CreateTicketRequest
): Promise<any> => {
  const headers = getHeaders();
  return requester.post(createTicketEndPoint, createTicket, {
    headers,
  });
};