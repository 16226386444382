import { FC } from "react";
import classNames from "classnames";
import styles from "./SkyButton.module.css";

export enum ButtonSize {
  LARGE,
  MEDIUM,
  SMALL,
  FIT_CONTENT,
}

export enum ButtonType {
  DEFAULT,
  ERROR,
  CANCEL,
}

export type Props = {
  text?: string;
  renderer?: () => JSX.Element;
  size: ButtonSize;
  type?: ButtonType;
  onClick: () => void;
  testId?: string;
  disabled?: boolean;
};
const SkyButton: FC<Props> = (props: Props) => {
  const getButtonSizeClass = (size: ButtonSize) => {
    switch (size) {
      case ButtonSize.LARGE:
        return styles.largeBtn;
      case ButtonSize.MEDIUM:
        return styles.mediumBtn;
      case ButtonSize.SMALL:
        return styles.smallBtn;
      case ButtonSize.FIT_CONTENT:
        return styles.fitContentBtn;
    }
  };

  const getButtonTypeClass = (type: ButtonType | undefined) => {
    switch (type) {
      case ButtonType.DEFAULT:
        return styles.defaultBtnType;
      case ButtonType.ERROR:
        return styles.errorBtnType;
      case ButtonType.CANCEL:
        return styles.cancelBtnType;
      case undefined:
        return styles.defaultBtnType;
    }
  };

  return (
    <button
      className={classNames(
        styles.skyButton,
        getButtonTypeClass(props.type),
        getButtonSizeClass(props.size),
        props.disabled ? styles.disabled : ""
      )}
      onClick={props.onClick}
      data-testid={props.testId}
    >
      {props.text ? props.text : props.renderer ? props.renderer() : ""}
    </button>
  );
};

export default SkyButton;
