import {FC, useEffect, useState} from "react";
import Editor from "ckeditor5-skytel-build";
import {CKEditor} from "@ckeditor/ckeditor5-react";

export type Props = {
  height: string;
  onChange: (data: string) => void;
  onFocus: () => void;
  isCleared?: boolean;
  isDisabled?: boolean;
};

const SkyRichTextEditor: FC<Props> = (props) => {
  const [value, setValue] = useState("");
  const [editor, setEditor] = useState<Editor>();

  useEffect(() => {
    if (props.isCleared === true) {
      setValue("");
    }
  }, [props.isCleared]);

  useEffect(() => {
    editor?.editing.view.change((writer) => {
      writer.setStyle(
        "height",
        props.height,
        editor.editing.view.document.getRoot()!
      );
    });
  }, [props.height, editor, editor?.editing.view]);

  return (
    <CKEditor
      editor={Editor}
      onReady={(editor) => {
        setEditor(editor);
      }}
      onFocus={(event, editor) => {
        // @ts-ignore
        document.getElementsByClassName("ck-powered-by")[0].style["display"] =
          "none";
        props.onFocus();
      }}
      disabled={props.isDisabled}
      data={value}
      onChange={(event, editor) => {
        setValue(editor.getData());
        props.onChange(editor.getData());
      }}
    />
  )
}

export default SkyRichTextEditor;